import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path d="M16.25 4.37428C15.3986 4.37427 14.5547 4.53324 13.7617 4.84303C12.643 2.52271 10.3828 1.36959 10.2797 1.31803C10.1929 1.27458 10.0971 1.25195 10 1.25195C9.9029 1.25195 9.80714 1.27458 9.72031 1.31803C9.61641 1.36959 7.35703 2.52271 6.23828 4.84303C5.44527 4.53324 4.60138 4.37427 3.75 4.37428C3.58424 4.37428 3.42527 4.44012 3.30806 4.55733C3.19085 4.67454 3.125 4.83352 3.125 4.99928V11.2493C3.125 13.0726 3.84933 14.8213 5.13864 16.1106C6.42795 17.3999 8.17664 18.1243 10 18.1243C11.8234 18.1243 13.572 17.3999 14.8614 16.1106C16.1507 14.8213 16.875 13.0726 16.875 11.2493V4.99928C16.875 4.83352 16.8092 4.67454 16.6919 4.55733C16.5747 4.44012 16.4158 4.37428 16.25 4.37428ZM9.375 16.8399C8.00054 16.6845 6.73131 16.0288 5.80921 14.9978C4.8871 13.9668 4.3766 12.6325 4.375 11.2493V10.0337C5.74946 10.189 7.01869 10.8447 7.94079 11.8758C8.8629 12.9068 9.3734 14.2411 9.375 15.6243V16.8399ZM9.375 11.6758C8.80183 10.8589 8.05768 10.1765 7.19425 9.67614C6.33081 9.17575 5.36878 8.86934 4.375 8.77818V5.65865C5.74946 5.81405 7.01869 6.46974 7.94079 7.50077C8.8629 8.53179 9.3734 9.86606 9.375 11.2493V11.6758ZM7.35547 5.39849C8.07578 3.89771 9.41875 2.95084 10 2.59381C10.5813 2.95162 11.9242 3.89849 12.6437 5.39849C11.4883 6.11354 10.5689 7.15281 10 8.38678C9.43107 7.1526 8.5113 6.11328 7.35547 5.39849ZM15.625 11.2493C15.6234 12.6325 15.1129 13.9668 14.1908 14.9978C13.2687 16.0288 11.9995 16.6845 10.625 16.8399V15.6243C10.6266 14.2411 11.1371 12.9068 12.0592 11.8758C12.9813 10.8447 14.2505 10.189 15.625 10.0337V11.2493ZM15.625 8.77818C14.6312 8.86934 13.6692 9.17575 12.8058 9.67614C11.9423 10.1765 11.1982 10.8589 10.625 11.6758V11.2493C10.6266 9.86606 11.1371 8.53179 12.0592 7.50077C12.9813 6.46974 14.2505 5.81405 15.625 5.65865V8.77818Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
