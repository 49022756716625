import { byteTestnetTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([
  // {
  //   pid: 1,
  //   lpAddress: '0xc2a21D8EDec8aD9c96C1eA6f56fb1C2faD8b18e5',
  //   token0: byteTestnetTokens.usdt,
  //   token1: byteTestnetTokens.weth,
  //   feeAmount: FeeAmount.LOW,
  // },
  // {
  //   pid: 3,
  //   lpAddress: '0x81F4E384ca15733D4253ABaA48C7B39DE5d18871',
  //   token0: byteTestnetTokens.usdt,
  //   token1: byteTestnetTokens.cake,
  //   feeAmount: FeeAmount.LOW,
  // },
  // {
  //   pid: 2,
  //   lpAddress: '0xe62C422c1E8083CE3b4526Ff0b16388354AB6E64',
  //   token0: bscTestnetTokens.cake2,
  //   token1: bscTestnetTokens.wbnb,
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 3,
  //   lpAddress: '0xc0E0F94a79Aabc6c655f308Da21D6EbDE64b0995',
  //   token0: bscTestnetTokens.mockB,
  //   token1: bscTestnetTokens.mockA,
  //   feeAmount: FeeAmount.LOW,
  // },
  // {
  //   pid: 4,
  //   lpAddress: '0xf7f2894abd4beE559521D754c5D481730E1C7d8C',
  //   token0: bscTestnetTokens.mockB,
  //   token1: bscTestnetTokens.mockA,
  //   feeAmount: FeeAmount.LOWEST,
  // },
  // {
  //   pid: 5,
  //   lpAddress: '0x5d9550E870D42Ae03Fab91508cC5722A80CF0b5e',
  //   token0: bscTestnetTokens.mockB,
  //   token1: bscTestnetTokens.mockA,
  //   feeAmount: FeeAmount.HIGH,
  // },
  // {
  //   pid: 6,
  //   lpAddress: '0x427d29C609A85AA3aaF87Aff65C392D72588ceC2',
  //   token0: bscTestnetTokens.cake2,
  //   token1: bscTestnetTokens.busd,
  //   feeAmount: FeeAmount.MEDIUM,
  // },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  // {
  //   pid: 0,
  //   lpSymbol: 'BEXC-USDT LP',
  //   lpAddress: '0x91B28e57832186f1F5bCCD28b90f17239a6B629d',
  //   token: byteTestnetTokens.usdt,
  //   quoteToken: byteTestnetTokens.weth,
  // },
  {
    pid: 0,
    lpSymbol: 'JIM-USDT LP',
    lpAddress: '0xC305e78014cc88DBF5ACC40Ad347C50890B533e8',
    token: byteTestnetTokens.usdt,
    quoteToken: byteTestnetTokens.cake,
  },
  // {
  //   pid: 2,
  //   lpSymbol: 'USDT-BEXC LP',
  //   lpAddress: '0xDea03d402F36e5cEca332ACdcD34a7d4793549B6',
  //   token: byteTestnetTokens.usdt,
  //   quoteToken: byteTestnetTokens.weth,
  // },
  // {
  //   pid: 4,
  //   lpSymbol: 'JIM-BNB LP',
  //   lpAddress: '0xa96818CA65B57bEc2155Ba5c81a70151f63300CD',
  //   token: bscTestnetTokens.cake,
  //   quoteToken: bscTestnetTokens.wbnb,
  // },
  // {
  //   pid: 10,
  //   lpSymbol: 'BNB-BUSD LP',
  //   lpAddress: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
  //   token: bscTestnetTokens.wbnb,
  //   quoteToken: bscTestnetTokens.busd,
  // },
  // {
  //   pid: 9,
  //   lpSymbol: 'BUSD-USDC LP',
  //   lpAddress: '0x7CA885d338462790DD1B5416ebe6bec75ee045a1',
  //   token: bscTestnetTokens.mockBusd, // coins[0]
  //   quoteToken: bscTestnetTokens.usdc, // coins[1]
  //   stableSwapAddress: '0xd5E56CD4c8111643a94Ee084df31F44055a1EC9F',
  //   infoStableSwapAddress: '0xaE6C14AAA753B3FCaB96149e1E10Bc4EDF39F546',
  //   stableLpFee: 0.0002,
  //   stableLpFeeRateOfTotalFee: 0.5,
  // },
  // {
  //   pid: 11,
  //   lpSymbol: 'USDT-BUSD LP',
  //   lpAddress: '0x9Fa2Ef2C3dF6F903F4b73047311e861C51a11B60',
  //   token: bscTestnetTokens.usdt, // coins[0]
  //   quoteToken: bscTestnetTokens.mockBusd, // coins[1]
  //   stableSwapAddress: '0xc418d68751Cbe0407C8fdd90Cde73cE95b892f39',
  //   infoStableSwapAddress: '0xaE6C14AAA753B3FCaB96149e1E10Bc4EDF39F546',
  //   stableLpFee: 0.0002,
  //   stableLpFeeRateOfTotalFee: 0.5,
  // },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default farms
