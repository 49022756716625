import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path d="M16.0418 7.47422L14.341 1.69844C14.3029 1.56903 14.224 1.45543 14.116 1.37461C14.008 1.29378 13.8767 1.25007 13.7418 1.25H6.259C6.1241 1.25007 5.99285 1.29378 5.88485 1.37461C5.77684 1.45543 5.69789 1.56903 5.65978 1.69844L3.959 7.47422C3.69216 8.35949 3.67819 9.30175 3.91868 10.1945C4.15917 11.0873 4.64453 11.8951 5.31994 12.5266C6.43095 13.5685 7.85847 14.2089 9.37541 14.3461V17.5H6.87541C6.70965 17.5 6.55068 17.5658 6.43347 17.6831C6.31626 17.8003 6.25041 17.9592 6.25041 18.125C6.25041 18.2908 6.31626 18.4497 6.43347 18.5669C6.55068 18.6842 6.70965 18.75 6.87541 18.75H13.1254C13.2912 18.75 13.4501 18.6842 13.5673 18.5669C13.6846 18.4497 13.7504 18.2908 13.7504 18.125C13.7504 17.9592 13.6846 17.8003 13.5673 17.6831C13.4501 17.5658 13.2912 17.5 13.1254 17.5H10.6254V14.3461C12.1423 14.2088 13.5698 13.5684 14.6809 12.5266C15.3563 11.8951 15.8416 11.0873 16.0821 10.1945C16.3226 9.30175 16.3087 8.35949 16.0418 7.47422ZM6.72619 2.5H13.2746L14.8442 7.82734C14.8512 7.85078 14.8574 7.87422 14.8637 7.89766C13.1903 8.49766 11.3059 7.77266 10.2832 7.25469C8.31525 6.25859 6.75275 6.13203 5.60041 6.32266L6.72619 2.5ZM13.8285 11.6133C12.7907 12.5851 11.4222 13.1258 10.0004 13.1258C8.57865 13.1258 7.2101 12.5851 6.17228 11.6133C5.66688 11.1397 5.30395 10.5342 5.12448 9.86517C4.94502 9.19618 4.95616 8.49034 5.15666 7.82734L5.19181 7.70859C6.12931 7.39141 7.61916 7.30703 9.71759 8.37031C10.5887 8.81172 11.9957 9.37422 13.5012 9.37422C14.0044 9.37667 14.5054 9.30829 14.9895 9.17109C14.9257 10.1028 14.5108 10.9756 13.8285 11.6133Z" fill="#CFD1D6"/>
    </Svg>
  );
};

export default Icon;