import { byteTestnetTokens } from '@pancakeswap/tokens'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '../..'

const priceHelperLps: SerializedFarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'JIM-USDT LP',
    lpAddress: '0xC305e78014cc88DBF5ACC40Ad347C50890B533e8',
    quoteToken: byteTestnetTokens.usdt,
    token: byteTestnetTokens.cake,
  },
  {
    pid: 3,
    lpSymbol: 'BEXC-USDT LP',
    lpAddress: '0x91B28e57832186f1F5bCCD28b90f17239a6B629d',
    quoteToken: byteTestnetTokens.usdt,
    token: byteTestnetTokens.weth,
  },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default priceHelperLps
