import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 25 24" {...props}>
      <path d="M12.4981 2.25C10.5697 2.25 8.68462 2.82183 7.08124 3.89317C5.47786 4.96451 4.22818 6.48726 3.49023 8.26884C2.75227 10.0504 2.55919 12.0108 2.93539 13.9021C3.3116 15.7934 4.2402 17.5307 5.60376 18.8943C6.96732 20.2579 8.70461 21.1865 10.5959 21.5627C12.4872 21.9389 14.4476 21.7458 16.2292 21.0078C18.0108 20.2699 19.5335 19.0202 20.6049 17.4168C21.6762 15.8134 22.2481 13.9284 22.2481 12C22.2453 9.41498 21.2172 6.93661 19.3893 5.10872C17.5614 3.28084 15.0831 2.25273 12.4981 2.25ZM20.7134 11.25H16.9793C16.8471 8.58187 16.0165 6.04969 14.6243 4.03125C16.2548 4.469 17.7123 5.39535 18.8008 6.68582C19.8893 7.97629 20.5567 9.56903 20.7134 11.25ZM12.4981 20.2397C10.7421 18.3394 9.67711 15.6459 9.51961 12.75H15.4765C15.319 15.6441 14.254 18.3394 12.4981 20.2397ZM9.51961 11.25C9.67711 8.35594 10.7384 5.66062 12.4981 3.76031C14.254 5.66062 15.319 8.35406 15.4765 11.25H9.51961ZM10.3718 4.03125C8.97961 6.04969 8.14899 8.58187 8.0168 11.25H4.28274C4.43938 9.56903 5.10681 7.97629 6.19533 6.68582C7.28385 5.39535 8.74129 4.469 10.3718 4.03125ZM4.28274 12.75H8.0168C8.14899 15.4181 8.97961 17.9503 10.3718 19.9688C8.74129 19.531 7.28385 18.6046 6.19533 17.3142C5.10681 16.0237 4.43938 14.431 4.28274 12.75ZM14.6243 19.9688C16.0165 17.9475 16.8471 15.4153 16.9793 12.75H20.7134C20.5567 14.431 19.8893 16.0237 18.8008 17.3142C17.7123 18.6046 16.2548 19.531 14.6243 19.9688Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
